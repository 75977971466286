import { render, staticRenderFns } from "./district-map-price.vue?vue&type=template&id=67479ac9"
import script from "./district-map-price.vue?vue&type=script&lang=js"
export * from "./district-map-price.vue?vue&type=script&lang=js"
import style0 from "./district-map-price.vue?vue&type=style&index=0&id=67479ac9&prod&lang=css"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports